import React from "react"

const ContactEN = () => <>
  <h2>Contact</h2>

  <p className="bigger-text text-center">
    For a detailed offer, please contact us during office hours <strong>between 8:00 to 16:00.</strong><br />
  </p>
  <div className="row">
    <div className="col-12 col-md-6 col-lg-6">
      <p><strong>HIBRID Sp. z o.o.</strong><br /> ul. Władysława Grabskiego 5
        <br />27- 200 Starachowice<br /> NIP: 664 213 44 49<br /> Regon: 364 567 644<br /> KRS: 0000 620 692</p>
      <h4>Quick contact</h4>
      <a href="mailto:a.bruderek@hibrid.com.pl">a.bruderek@hibrid.com.pl</a>
    </div>
  </div>
</>

export default ContactEN
