import React from "react"

const ContactPL = () => <>
  <h2>Kontakt</h2>

  <p className="bigger-text text-center">
    W sprawie szczegółowej oferty prosimy o kontakt w godzinach <strong>od 8:00 do 16:00.</strong><br />
  </p>
  <div className="row">
    <div className="col-12 col-md-6 col-lg-6">

      <p><strong>HIBRID Sp. z o.o.</strong>
        <br />ul. Władysława Grabskiego 5<br />
        27- 200 Starachowice<br />
        NIP: 664 213 44 49<br />
        Regon: 364 567 644<br />
        KRS: 0000 620 692<br />
      </p>
      <h4>Szybki kontakt</h4>
      <a href="mailto:a.bruderek@hibrid.com.pl">a.bruderek@hibrid.com.pl</a>
    </div>
  </div>
</>

export default ContactPL
