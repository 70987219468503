import React from "react"
import ContactPL from "./contact.pl"
import ContactEN from "./contact.en"
import ContactDE from "./contact.de"

const ContactContent = ({ lang }) => {
  if (lang === "de") {
    return <ContactDE />
  } else if (lang === "en") {
    return <ContactEN />
  }
  return <ContactPL />
}

export default ContactContent