import React from "react"

const ContactDE = () => <>
  <h2>Kontakt</h2>
  <p className="bigger-text text-center">Sie haben Fragen, wünschen weitere Informationen oder haben Anregungen für
    uns? <br /> Wir freuen uns über Ihre Nachricht und helfen Ihnen gerne weiter. <br /> Wir bitten um
    Kontaktaufnahme <strong>von 8:00 bis 16:00 Uhr</strong><br />
  </p>
  <div className="row">
    <div className="col-12 col-md-6 col-lg-6">
      <p><strong>HIBRID Sp. z
        o.o.</strong><br /> ul. Władysława Grabskiego 5<br /> 27-200 Starachowice<br /> NIP: 664 213 44
        49<br /> Regon: 364
        567 644<br /> KRS: 0000 620 692
      </p>
      <h4>Schneller Kontakt</h4>
      <a href="mailto:a.bruderek@hibrid.com.pl">a.bruderek@hibrid.com.pl</a>
    </div>
  </div>
</>

export default ContactDE
